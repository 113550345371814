//
// Body text
//

.p,
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

b,
strong {
  font-family: $font-family--bold;
}

i {
  font-style: italic;
  //font-family: $font-family--light;
}

.text-lg {
  font-size: $font-size-lg;
  //color: rgba(var(--text-color-rgb), 0.86);
}

.text-sm {
  font-size: $font-size-sm;
  color: rgba(var(--text-color-rgb), 0.76);
}

.text-medium {
  font-size: 1.7rem;
  color: rgba(var(--text-color-rgb), 0.5);
}

small,
.small {
  font-size: 80%; // Add the correct font size in all browsers
}

.text-nowrap {
  white-space: nowrap !important;
}

hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid $border-color-base;
}

mark,
.mark {
  background: $mark-bg;
}

// Links

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
  cursor: pointer;
  transition: $transition-default;

  @include hover-focus {
    color: $link-color-hover;
    text-decoration: $link-decoration-hover;
  }

  @include hover-focus-active {
    outline: none;
  }

  // And undo these styles for placeholder links/named anchors (without href)
  // which have not been made explicitly keyboard-focusable (without tabindex).
  // It would be more straightforward to just use a[href] in previous block, but that
  // causes specificity issues in many other styles that are too complex to fix.
  // See https://github.com/twbs/bootstrap/issues/19402
  &:not([href]):not([tabindex]) {
    @include self-hover-focus {
      color: inherit;
      text-decoration: none;
    }
  }
}

.link-secondary {
  text-decoration: underline;
  color: var(--text-color);

  @include hover-focus {
    text-decoration: none;
    color: var(--text-color);
  }
}

.link-underline {
  text-decoration: underline;
}

.primary-label {
  text-transform: uppercase;
  background-color: $brand-accent;
  color: $brand-white;
  padding: 3px 5px;
  display: inline;
}

.color-gray {
  color: rgba(var(--text-color-rgb), 0.5);
}

.color-accent {
  color: $brand-accent;
}

pre {
  font-size: $font-size-base;
  background: $brand-black;
  color: $brand-white;
  white-space: pre-wrap;
  padding: 15px;
  margin: 15px 0;
}

.filling-highlight {
  padding: 1px 4px 2px 4px;
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-image: $brand-text-highlight-active;
  transition: $transition-default;
  transition-property: background-size;
  transition-duration: 0.6s;
}

.filling-highlight--filled {
  background-size: 100% 100%;
}

.tooltip-title {
  border-bottom: dashed 1px $text-color;
  color: $brand-dark;
  cursor: help;
}

.text {
  &-white {
    --text-color: #{$brand-white} !important;
    --text-color-rgb: #{hexToRGB($brand-white)} !important;
  }

  &-dark {
    --text-color: #{$brand-dark} !important;
    --text-color-rgb: #{hexToRGB($brand-dark)} !important;
  }

  &-accent {
    --text-color: #{$brand-accent} !important;
    --text-color-rgb: #{hexToRGB($brand-accent)} !important;
  }

  &-gray {
    --text-color: #{$brand-gray} !important;
    --text-color-rgb: #{hexToRGB($brand-gray)} !important;

    &.bg-dark {
      --text-color: rgba(#{hexToRGB($brand-white)}, 0.5) !important;
      --text-color-rgb: rgba(#{hexToRGB($brand-white)}, 0.5) !important;
    }
  }

  &-black {
    --text-color: #{$brand-black} !important;
    --text-color-rgb: #{hexToRGB($brand-black)} !important;
  }
}
