@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin self-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin max-height($fs: 16, $lh: 20, $quant: 2) {
  font-size: $fs + px;
  line-height: ($lh/$fs);
  max-height: (($lh/$fs) * $quant + em);
  overflow: hidden;
  @if ($quant == 1) {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin nowrap() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Transfer of long words to the next line
@mixin break-word() {
  overflow-wrap: break-word; //does not support IE, Firefox; is a copy of word-wrap
  word-wrap: break-word;
}

@mixin clear-btn-styles {
  padding: 0;
  border: 0;
  background-color: transparent;
}

@mixin upper-component-angle() {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: inherit;
    transform: skewY($global-skew-degree * -1);
    height: var(--beveled-corner-maker-height);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    bottom: auto;
    transform-origin: left;
  }

  .beveled-border-maker:before {
    content: '';
    transform: skewY($global-skew-degree * -1);
    top: -1px;
    transform-origin: left;
  }
}

@mixin angle($pseudo, $angle: $global-skew-degree, $background: var(--skew-bg)) {
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');
    position: relative;
    overflow: hidden;
    z-index: 1;
    --offset-ratio: 1.8;

    @media (max-width: $screen-sm-max) {
      --offset-ratio: 1.5;
    }

    @media (max-width: $screen-xs-max) {
      --offset-ratio: 1.3;
    }

    #{$selector} {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background-color: $background;
      transform: skewY($angle * -1);
      height: var(--beveled-corner-maker-height);
      z-index: -1;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    @if $pseudo == 'before' {
      padding-top: calc(var(--section-offset) * var(--offset-ratio));

      #{$selector} {
        top: calc(-1 * var(--beveled-corner-maker-height));
        bottom: auto;
        transform-origin: right;
      }

      .beveled-border-maker:before {
        content: '';
        transform: skewY($angle * -1);
        top: -1px;
        transform-origin: right;
      }
    }

    @if $pseudo == 'after' {
      padding-bottom: calc(var(--section-offset) * var(--offset-ratio));

      #{$selector} {
        top: auto;
        bottom: calc(-1 * var(--beveled-corner-maker-height));
        transform-origin: left;
      }

      .beveled-border-maker:after {
        content: '';
        transform: skewY($angle * -1);
        bottom: -1px;
        transform-origin: left;
      }
    }

    @if $pseudo == 'both' {
      padding-top: calc(var(--section-offset) * var(--offset-ratio));
      padding-bottom: calc(var(--section-offset) * var(--offset-ratio));

      &:before {
        top: calc(-1 * var(--beveled-corner-maker-height));
        bottom: auto;
        transform-origin: right;
      }

      .beveled-border-maker:before {
        content: '';
        transform: skewY($angle * -1);
        top: -1px;
        transform-origin: right;
      }

      &:after {
        top: auto;
        bottom: calc(-1 * var(--beveled-corner-maker-height));
        transform-origin: left;
      }

      .beveled-border-maker:after {
        content: '';
        transform: skewY($angle * -1);
        bottom: -1px;
        transform-origin: left;
      }
    }
  }
}

@mixin toggle-fade($action: 'show') {
  @if $action == 'show' {
    position: static;
    opacity: 1;
    pointer-events: auto;
    transition: $transition-opacity;
  } @else {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: none;
  }
}
