html {
  min-height: 100vh;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: rgba(var(--text-color-rgb), 0.86);
  background-color: #eee;
}

body,
#root,
.site-wrap {
  min-height: inherit;
}

.site-wrap {
  overflow-x: hidden;
  min-width: $site-min-width;
  max-width: $site-max-width;
  background: $body-bg-color;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .modal-open & {
    overflow: initial;
  }
}

.main-wrap {
  flex: 1 0 auto;
  min-height: 100px;

  > *:not(.customer-map-block-wrapper):first-child {
    @include upper-component-angle();

    .container {
      position: relative;
    }
  }

  > .skew-bg:not(:first-of-type) {
    @include angle(before);
  }

  > .customer-map-block-wrapper:not(:first-of-type) {
    @include angle(before);
    padding-top: 0;
  }
}

.footer {
  flex: 0 0 auto;
}

.beveled-border-maker:before,
.beveled-border-maker:after {
  /* border or box-shadow caused artifact, those couldn't be removed neither backface, nor translateZ, so used :after  pseudo.*/
  position: absolute;
  left: 0;
  right: 0;
  height: 5px;
  background-color: var(--skew-border-color);
}

.bg-dark {
  background-color: $brand-dark;
  color: var(--text-color);

  &.section + &.section:not(.has-beveled-corner) {
    padding-top: 0;
  }

  + .customer-map-block-wrapper,
  + .skew-bg {
    --skew-bg: #{$brand-dark};
  }
}

.bg-accent {
  background-color: $brand-accent;
  color: var(--text-color);

  &.section + &.section:not(.has-beveled-corner) {
    padding-top: 0;
  }

  + .customer-map-block-wrapper,
  + .skew-bg {
    --skew-bg: #{$brand-accent};
  }
}

.bg-gray {
  background-color: $brand-light-gray;

  &.section + &.section:not(.has-beveled-corner) {
    padding-top: 0;
  }

  + .customer-map-block-wrapper,
  + .skew-bg {
    --skew-bg: #{$brand-light-gray};
  }
}

.initial-background {
  background-color: $brand-white;

  &.section + &.section:not(.has-beveled-corner) {
    padding-top: 0;
  }

  + .customer-map-block-wrapper,
  + .skew-bg {
    --skew-bg: #{$brand-white};
  }
}

.customer-map-block-wrapper {

  + .skew-bg {
    --skew-bg: #{$brand-white};
  }
}

.beveled-border-dark {
  --skew-border-color: #{$brand-dark};
}

.beveled-border-accent {
  --skew-border-color: #{$brand-accent};
}

.beveled-border-gray {
  --skew-border-color: #{$brand-light-gray};
}

.border {
  &-white {
    --border-color: #{$brand-white};
  }

  &-dark {
    --border-color: #{$brand-dark};
  }

  &-accent {
    --border-color: #{$brand-accent};
  }

  &-gray {
    --border-color: #{$brand-gray};
  }
}
