:root {
  --text-color: #{$brand-dark};
  --text-color-rgb: #{hexToRGB($brand-dark)};
  --header-height: 86px;
  --section-offset: 75px;
  --card-bg: #{$brand-light-gray};
  --beveled-corner-maker-height: 100px;
  --btn-tag-bg: #{$brand-light-gray};

  .bg-gray {
    --card-bg: #{$brand-white};
  }

  .theme-dark {
    --text-color: #{$brand-white};
    --text-color-rgb: #{hexToRGB($brand-white)};

    .btn-tag,
    .btn-tag-2,
    .customer-card {
      --text-color: #{$brand-dark};
      --text-color-rgb: #{hexToRGB($brand-dark)};
    }
  }

  .theme-accent {
    --text-color: #{$brand-white};
    --text-color-rgb: #{hexToRGB($brand-white)};

    .btn-tag,
    .btn-tag-2,
    .customer-card {
      --text-color: #{$brand-dark};
      --text-color-rgb: #{hexToRGB($brand-dark)};
    }
  }

  .theme-gray {
    --btn-tag-bg: #{$brand-white};
  }

  @media (max-width: $screen-md-max) {
    --section-offset: 50px;
  }
}
