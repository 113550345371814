@import '../../scss/utils/mixin';
@import '../../scss/utils/variables';
@import '../../scss/utils/functions';

.steps-block-item {
  position: relative;
  padding-top: 24px;

  &__title {
    margin-top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: $grid-gutter-width / 2;
    top: 0;
    width: 75px;
    height: 4px;
    background-color: $brand-accent;
  }

  ul {
    .site-wrap & {
      margin-bottom: 0;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .theme-accent &:before {
    background-color: $brand-white;
  }
}
