/* React-tooltip overrides */
div.__react_component_tooltip {
  font-size: 1.5rem;
  color: $brand-dark;
  font-family: $font-family--bold;
  opacity: 1 !important;
  box-shadow: $b-shadow;
  border-radius: 0;
}



/* Term tooltip content */

.tooltip-inner {
  .glossary-tip-content {
    width: 310px;
  }

  .glossary-tip-title {
    color: $brand-white;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1em;
  }

  .glossary-tip-intro {
    font-size: 1.3rem;
  }

  .glossary-tip-mdt {
    margin: 0;
    font-size: 1.3rem;

    a {
      text-decoration: underline;
      color: $brand-white;
    }
  }
}
