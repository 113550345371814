$nav-tabs-link-color: $brand-dark;
$nav-tabs-link-bg: transparent;

$nav-tabs-link-active-color: $brand-accent;
$nav-tabs-link-active-bg: transparent;

.tabs {
  .nav-tabs {
    margin: 0 0 35px;

    @media (max-width: $screen-md-max) {
      margin-bottom: 20px;
    }

    @media (max-width: $screen-md-max) {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow-x: auto;
    }
  }

  .nav-link {
    margin: 0 20px 0 0;
    padding: 5px 5px;
    transition: $transition-default;
    color: $nav-tabs-link-color;
    border-bottom: 3px solid transparent;
    background-color: $nav-tabs-link-bg;

    @media (max-width: $screen-md-max) {
      margin-right: 15px;
    }

    @include hover-focus {
      color: $link-color;
    }

    &.disabled {
      color: $disabled-color;
    }
  }

  .nav-link.active,
  .nav-link.acc-link:not(.collapsed) {
    border-bottom-color: $nav-tabs-link-active-color;
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    cursor: default;
  }
}
