// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 25rem;
$dropdown-split-toggle-width: 3rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: #fff;
$dropdown-divider-bg: #e9ecef;
$dropdown-box-shadow: $b-shadow;

$dropdown-link-color: #{rgba(var(--text-color-rgb), 0.5)};
$dropdown-link-hover-color: $brand-dark;
$dropdown-link-hover-bg: $brand-light-gray;

$dropdown-link-active-color: $brand-accent;
$dropdown-link-active-bg: $brand-accent;

$dropdown-link-disabled-color: $disabled-color;

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1.5rem;

@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: (($line-height-base / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

.dropdown-toggle {
  @include clear-btn-styles;
  position: relative;
  padding: 1rem 4rem 1rem 1.5rem;
  background-color: $brand-light-gray;
  color: rgba(var(--text-color-rgb), 0.5);

  &:after {
    font-family: 'Font Awesome 5 Free';
    content: '\f107';
    position: absolute;
    top: 53%;
    right: 1.5rem;
    transform: translateY(-50%);
    font-size: 2rem;
  }

  &.dropdown-toggle-split {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: $dropdown-split-toggle-width;
    padding: 0;

    &:after {
      top: 53%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  &.dropdown-split {
    padding-right: $dropdown-split-toggle-width;

    .dropdown-menu {
      left: 0 !important;
      top: 100% !important;
      transform: none !important;
    }
  }
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  width: 100%;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border-radius: $border-radius-base;
  box-shadow: $dropdown-box-shadow;

  &.show {
    display: block;
  }
}

// stylelint-enable no-duplicate-selectors

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  @include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  text-decoration: none;

  @include hover-focus {
    color: $dropdown-link-color;
    text-decoration: underline;
  }

  &.active {
    pointer-events: none;
    color: $dropdown-link-active-color;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    background-image: none;
    cursor: default;
    pointer-events: none;
  }
}
