.site-wrap {
  ol,
  ul {
    &:not(.list-reset) {
      list-style: none;
      padding: 0;
    }
  }

  .list-square ul,
  ul.list-square {
    margin-bottom: 3rem;
    margin-top: 2rem;

    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px;
      color: $text-color;

      &:before {
        content: '■';
        position: absolute;
        top: -9px;
        left: 1px;
        font-size: 26px;
        color: rgba(var(--text-color-rgb), 0.2);
      }
    }
  }

  .list-arrow ul,
  ul.list-arrow {
    margin-bottom: 3rem;
    margin-top: 2rem;

    li {
      position: relative;
      padding-left: 32px;
      margin-bottom: 23px;
      color: $text-color;

      &:before {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        top: -1px;
        left: 1px;
        font-size: 18px;
        color: $brand-accent;
      }

      @media (max-width: $screen-md-max) {
        margin-bottom: 16px;
        padding-left: 24px;

        &:before {
          font-size: 14px;
          top: 2px;
        }
      }
    }
  }

  ol {
    counter-reset: list 0; // Start counter from next digit

    @media (max-width: $screen-sm-max) {
      padding-left: 0;
    }

    > li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 30px;

      @media (max-width: $screen-md-max) {
        padding-left: 25px;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        counter-increment: list; // counter up (list = +1, list list = +1+1...)
        content: counter(list) '. '; // delimeter for counter and content
        color: var(--text-color);
      }
    }

    // OL - Level 2
    ol {
      > li {
        &:before {
          content: counters(list, '.') '. '; // delimeter for counter and content
          color: var(--text-color);
        }
      }
    }
  }
}
