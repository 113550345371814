@mixin form-control {
  display: block;
  width: 100%;
  padding: 2px 0 10px;
  font-size: $input-font-size;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: 0;
  border-bottom: 2px solid rgba(var(--text-color-rgb), 0.2);
  border-radius: $border-radius-base;
  box-shadow: none;
  appearance: none;
  transition: $transition-default;

  //&:not(:placeholder-shown),
  &:focus {
    border-bottom-color: var(--text-color);
    outline: 0;
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Placeholder
  &::placeholder {
    color: rgba(var(--text-color-rgb), 0.4);
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    cursor: not-allowed;
    opacity: 1;
  }

  @at-root {
    textarea#{&} {
      resize: none;
    }
  }
}

@mixin form-control-2 {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: $input-font-size;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: 2px solid rgba(var(--text-color-rgb), 0.2);
  border-radius: $border-radius-base;
  box-shadow: none;
  appearance: none;
  transition: $transition-default;

  &:focus {
    border-color: var(--text-color);
    outline: 0;
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Placeholder
  &::placeholder {
    color: rgba(var(--text-color-rgb), 0.4);
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    cursor: not-allowed;
    opacity: 1;
  }

  .has-error & {
    border-color: $brand-error;
  }

  .has-success & {
    border-color: $brand-success;
  }

  @at-root {
    textarea#{&} {
      resize: none;
    }
  }
}

.form {
  .form-errors {
    display: none;
  }

  .validation-message {
    font-size: $font-size-sm;

    > span {
      display: inline-block;
      padding: 5px 0;
    }
  }

  .error-message {
    color: $brand-error;
  }

  input,
  select,
  textarea {
    @include form-control;
  }

  .has-error {
    input,
    select,
    textarea {
      border-bottom-color: $brand-error;
    }
  }

  .has-success {
    input,
    select,
    textarea {
      border-bottom-color: $brand-success;
    }
  }
}

.form-row {
  @include clearfix;
  margin: 0 ($input-gutter-width / -2);

  [class*='col'] {
    float: left;
    padding-left: $input-gutter-width / 2;
    padding-right: $input-gutter-width / 2;
  }
}

.form-control-wrap label,
.form-label {
  font-size: 1.1rem;
  color: rgba(var(--text-color-rgb), 0.4);
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  @include form-control;
}

.form-control-2 {
  @include form-control-2;
}

.form-control-text-field {
  position: relative;

  label,
  .form-label {
    font-size: $font-size-base;
    position: absolute;
    transition: font-size 0.15s ease, top 0.15s ease;
    cursor: text;
  }

  input,
  textarea {
    &::placeholder {
      color: transparent;
    }
  }

  &:focus-within,
  &.has-value {
    label {
      font-size: 1.1rem;
      top: -10px;
      cursor: default;
    }
  }
}

.sc-jss-placeholder-error {
  color: $brand-error;
}

.grecaptcha-badge {
  visibility: hidden;
}