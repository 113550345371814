// =====================================
// Fonts
// =====================================

$_font-family-sans-serif: Helvetica, Arial, sans-serif;
$_font-family-serif: 'Times New Roman', serif;

$font-family--medium: 'Gilroy-Medium', $_font-family-sans-serif;
$font-family--bold: 'Gilroy-Bold', $_font-family-sans-serif;
$font-family--semibold: $font-family--medium;
$font-family--italic: 'IBMPlexSans-Italic', $_font-family-sans-serif;
$font-family--bolditalic: $font-family--italic;

// =====================================
// Grid
// =====================================

$enable-grid-classes: true;
$grid-columns: 12;
$grid-gutter-width: 30px;
$form-grid-gutter-width: 10px;

$screen-xl-min: 1320px;
$container-xl: 1260px;

$screen-lg-max: ($screen-xl-min - 0.02);
$screen-lg-min: 1160px;
$container-lg: 1120px;

$screen-md-max: ($screen-lg-min - 0.02);
$screen-md-min: 768px;
$container-md: 720px;

$screen-sm-max: ($screen-md-min - 0.02);
$screen-sm-min: 540px;
$container-sm: 540px;

$screen-xs-max: ($screen-sm-min - 0.02);
$screen-xs-min: 0;

$site-min-width: 300px;
$content-max-width: 1200px;
$site-max-width: 1920px;

$grid-breakpoint-min: $screen-lg-min;
$grid-breakpoint-max: $screen-md-max;

// =====================================
// Colors
// =====================================

// brands
$brand-accent: #0eb35b;
$brand-secondary: #00a9b7;
$brand-accent-gradient: linear-gradient(125deg, $brand-accent 0%, $brand-secondary 100%);
$brand-white: #fff;
$brand-dark: #172239;
$brand-gray: rgba($brand-dark, 0.2);
$brand-light-gray: #f1f2f5;
$brand-light-gray-2: #e9eaed;
$brand-black: #000;
$brand-text-highlight-color: #99dde2;
$brand-text-highlight-active: linear-gradient(
  to right,
  $brand-text-highlight-color 0,
  $brand-text-highlight-color 100%
);

$body-bg-color: $brand-white;
$body-bg-outer-color: $brand-light-gray;

$headings-color: $brand-dark;
$text-color: rgba(var(--text-color-rgb), 0.86);
$text-footer-color: $brand-dark;
$disabled-color: rgba(var(--text-color-rgb), 0.2);

$link-color: $brand-accent;
$link-color-hover: #109653;
$link-color-active: $link-color-hover;
$link-color-active-hover: $link-color;

$border-color-base: rgba(var(--text-color-rgb), 0.2);

$mark-bg: $brand-light-gray;

// =====================================
// Typography
// =====================================

$font-family-base: $font-family--medium;

$font-size-root: 10px;
$font-size-base: 1.8rem; // 1.8 for mobile and tablet
$font-size-lg: 2.3rem;
$font-size-sm: 1.5rem;
$font-size-xs: 1.3rem;

$line-height-base: 1.47;

// Headings
$headings-margin-bottom: 0.5rem;
$headings-font-family: $font-family--bold;

$font-size-h1: 7.8rem; // 8.8 according to design
$font-size-h2: 4.4rem; // 5 according to design
$font-size-h3: 3.8rem;
$font-size-h4: 2.8rem;
$font-size-h5: 2.3rem;
$font-size-h6: 2rem;

$line-height-h1: 1.14;
$line-height-h2: 1.14;
$line-height-h3: 1.35;
$line-height-h4: 1.35;
$line-height-h5: 1.35;
$line-height-h6: 1.25;

$head-margin-h1: 0 0 0.7em;
$head-margin-h1-small: 0.8em 0 0.7em;
$head-margin-h2: 0.8em 0 0.4em;
$head-margin-h3: 0.8em 0 0.4em;
$head-margin-h4: 1em 0 0.75em;
$head-margin-h5: 1.25em 0 0.5em;
$head-margin-h6: 1.25em 0 0.9em;

// Paragraph
$paragraph-margin-bottom: 1em;

// Links
$link-decoration: none;
$link-decoration-hover: none;
$transition-default: all 0.35s ease;
$transition-border: border 0.35s ease;
$transition-opacity: opacity 0.35s linear;

// =====================================
// forms
// =====================================

$input-font-size: $font-size-base;
$input-line-height: $line-height-base;
$input-bg: transparent;
$input-color: $text-color;
$input-gutter-width: 20px;

$brand-success: #0eb35b;
$brand-error: #ff391a;

// =====================================
// z-index
// =====================================

$zindex-dropdown: 10;
$zindex-header: 11;
$zindex-backdrop: 1000;
$zindex-banner: 1000;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-overlay: 1080;

// =====================================
// Decorates
// =====================================

$nav-b-shadow-l2: 0 2px 7px rgba(0, 0, 0, 0.35);
$nav-b-shadow-l3: 0 2px 7px rgba(0, 0, 0, 0.35);
$b-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.13);
$b-shadow-hover: 0 4px 14px 2.2px rgba(0, 0, 0, 0.13);
$border-radius-base: 0;
$global-skew-degree: 3deg;

// =====================================
// Tables
// =====================================
$table-cell-padding-x: 0.5rem;
$table-cell-padding-y: 0.3rem;
$table-bg: transparent;
$table-margin-bottom: 1em;
$table-color: $text-color;
$table-border-width: 1px;
$table-border-color: #999999;
