// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

// Modals

// Padding applied to the modal body

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-content-border-radius: $border-radius-base;

$modal-transition: all 0.3s ease-out;

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  outline: 0;
  transition: opacity 0.15s linear;

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }

  //&.experience-editor {
  //    position: static;
  //}
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  --modal-lg: 100%;
  --modal-sm: 100%;
  --close-size: 40px;
  position: relative;
  width: auto;
  pointer-events: none;
  max-width: var(--modal-lg);

  &.modal-sm {
    max-width: var(--modal-sm);
  }

  @media (min-width: $screen-md-min) {
    --close-size: 50px;
    --modal-lg: calc(#{$container-md} - #{$grid-gutter-width});
    --modal-sm: 500px;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  @media (min-width: $screen-lg-min) {
    --modal-lg: 750px;
    --close-size: 60px;
  }

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transition: $modal-transition;

    @media (min-width: $screen-md-min) {
      transform: translate(0, -25%);
    }
  }

  .modal.show & {
    @media (min-width: $screen-md-min) {
      transform: translate(0, 0);
    }
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (#{$modal-dialog-margin} * 2));

  @media (min-width: $screen-md-min) {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  pointer-events: auto;
  background-color: $brand-white;
  background-clip: padding-box;
  border-radius: $modal-content-border-radius;
  box-shadow: 0 14px 32px 0 rgba(0, 0, 0, 0.24);
  outline: 0;

  @media (max-width: $screen-sm-max) {
    min-height: 100vh;
  }
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: rgba($brand-dark, 0.6);

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: 1;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: 30px calc(var(--close-size) + 15px) 60px 15px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: skewY($global-skew-degree * -1);
    transform-origin: left;
    background-color: $brand-dark;
  }

  @media (min-width: $screen-md-min) {
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 70px;
  }

  .modal-title {
    margin: 0;
  }

  &-empty {
    padding-top: var(--close-size);
    padding-bottom: 0;

    &:before {
      content: none;
    }

    & + .modal-body-centered {
      padding-bottom: var(--close-size);
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--close-size);
    height: var(--close-size);
    border: 0;
    background-color: $brand-accent;
    color: $brand-white;
    font-size: calc(var(--close-size) / 2);
    transition: $transition-default;

    @include hover-focus {
      background-color: $link-color-hover;
    }

    &:before {
      font-family: 'Font Awesome 5 Free';
      content: '\f00d';
    }

    span {
      display: none;
    }
  }
}

.modal-header-inner {
  position: relative;
}

.modal-header-image {
  margin-bottom: 15px;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 30px;

  &.modal-body-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media (max-width: $screen-sm-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  > .h1,
  > .h2,
  > .h3,
  > .h4,
  > .h5 {
    margin-top: 0;
  }
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 30px;

  @media (max-width: $screen-sm-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  // Easily place margin between footer elements
  > :not(:first-child) {
    margin-left: 0.25rem;
  }
  > :not(:last-child) {
    margin-right: 0.25rem;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
