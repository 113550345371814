@mixin font-face($font-family, $font) {
  @font-face {
    font-family: '#{$font-family}';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('#{$font}'), local('#{$font}'), url('../../fonts/#{$font}/#{$font}.woff2') format('woff2'),
      url('../../fonts/#{$font}/#{$font}.woff') format('woff');
  }
}

@include font-face('Gilroy-Bold', Gilroy-Bold);
@include font-face('Gilroy-Medium', Gilroy-Medium);
@include font-face('IBMPlexSans-Italic', IBMPlexSans-Italic);
@include font-face('Font Awesome 5 Free', FontAwesomeCustom);
