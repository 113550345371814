h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $headings-font-family;
  word-wrap: break-word;
  font-weight: normal;
  font-style: normal;
  color: var(--text-color);
}

h1,
.h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  margin: $head-margin-h1;
  font-family: $font-family--semibold;

  @media (max-width: $screen-lg-max) {
    font-size: 5.4rem;
  }

  @media (max-width: $screen-md-max) {
    font-size: 4.8rem;
  }

  @media (max-width: $screen-sm-max) {
    font-size: 3.8rem;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  margin: $head-margin-h2;

  @media (max-width: $screen-md-max) {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
  margin: $head-margin-h3;

  @media (max-width: $screen-sm-max) {
    font-size: 2.8rem;
  }
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
  margin: $head-margin-h4;

  @media (max-width: $screen-md-max) {
    font-size: 2.2rem;
  }
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: $line-height-h5;
  margin: $head-margin-h5;

  @media (max-width: $screen-md-max) {
    font-size: 2rem;
  }
}

h6,
.h6 {
  font-size: $font-size-h6;
  line-height: $line-height-h6;
  margin: $head-margin-h6;
  font-family: $font-family--semibold;
  color: rgba(var(--text-color-rgb), 0.5);
  text-transform: uppercase;

  @media (max-width: $screen-md-max) {
    font-size: 1.7rem;
  }
}
