img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
}

svg {
  // Workaround for the SVG overflow bug in IE10/11 is still required.
  // See https://github.com/twbs/bootstrap/issues/26878
  overflow: hidden;
  vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive,
.site-wrap img,
.modal img {
  display: block;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get
}

.scChromeData,
.scpm {
  display: none !important;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.t-last-br {
  display: none;
}
