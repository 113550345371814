// =====================================
// common tables, need to set table class
// =====================================
.table {
  width: 100%;
  margin-bottom: $table-margin-bottom;
  color: $table-color;
  background-color: $table-bg;

  &.table--w-auto {
    width: auto;
  }

  th,
  td {
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
    padding: $table-cell-padding-y $table-cell-padding-x;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  &--column-fixed {
    width: 100%;
    border-collapse: collapse;

    tr {
      position: relative;

      td {

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 10;
          background-color: #FFFFFF;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            width: $table-border-width;
            height: 100%;
            background-color: $table-border-color;
          }

          @media (max-width: $screen-md-max) {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 100%;
              width: $table-border-width;
              height: 100%;
              background-color: $table-border-color;
            }
          }
        }
      }
    }
  }

  &--tr-rotation {
    tr:nth-child(even) {
      td {
        background-color: #FFFFFF;
      }
      
    }
  
    tr:nth-child(odd) {
      td {
        background-color: #d6d6d6;
      }
    } 
  }

  .td {
    
    &--blue {
        display: table-cell !important;
        background-color: #00A9B7 !important;
        color: #FFFFFF;
      
    }

    &--dark {
        display: table-cell !important;
        background-color: #172239 !important;;
        color: #FFFFFF;
      
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

// =====================================
// custom tables
// =====================================
.blog-post table.table-custom td {
  width: auto;
}

.table-custom {

  th {
    border: $table-border-width solid $table-border-color;
    padding: 3px 5px;
    background-color: #149d93;
    font-weight: 500;
    text-align: center;
    color: #fff;
    vertical-align: bottom;
  }

  th.w-15 {
    width: 15%;
    min-width: auto;
  }

  th:nth-child(2):not(.w-15) {
    min-width: 40%;
  }

  th.pink {
    background-color: #a15a7f;
  }

  th.dark-blue {
    background-color: #008397;
  }

  td {
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
    padding: 3px 5px;
    background-color: #cccccc;
    font-weight: 300;
    color: #4e4e4e;
    width: auto;
  }

  tr {
    border: none;
  }

  tr.transparent td {
    background-color: transparent;
  }

  td[rowspan] {
    text-align: center;
    padding: 3px 8px;
    white-space: nowrap;
    vertical-align: top;
  }

  td.point:before {
    content: none;
    padding-right: 5px;
    font-size: 20px;
    line-height: 10px;
  }

  td.green {
    text-align: center;
    background-color: #64d473;
    white-space: nowrap;
  }

  td.ta-center {
    text-align: center;
    vertical-align: middle;
  }

  td.orange {
    background-color: #fff798;
    text-align: center;
    white-space: nowrap;
  }

  td.red {
    text-align: center;
    background-color: #ff6060;
    white-space: nowrap;
  }

  td sup {
    font-size: 8px;
    top: -8px;
    margin-left: 2px;
    margin-right: -6px;
  }

  td div {
    white-space: nowrap;
  }
}

@media (max-width: $screen-sm-max) {
  .table-custom td.point{
    min-width: 200px;
  }
}

